<template>
    <h1>За нас...</h1>
    <about-us></about-us>
</template>

<script>
import AboutUs from "../components/about-us/AboutUs.vue"
export default {
    components: {
        AboutUs,
    }
}
</script>

<style lang="scss" scoped>
    h1 {
        margin-top: 100px;
    }
</style>