<template>
  <div class="homepage-wrapper">
    <div>
      <h1 class="homepage-heading">Имоти по твой вкус, отговарящи на твоя стил</h1>
    </div>
    <div>
      <router-link to="/for-sale"><button class="gold-shadowed btn-medium"> Продажби </button></router-link>
      <router-link to="/for-rent"><button class="gold-shadowed btn-medium"> Наеми </button></router-link>
    </div>
  </div>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    "
  >
    <base-loading v-if="isLoading"></base-loading>
    <recommended-properties></recommended-properties>
  </div>
</template>

<script>
import { usePropertiesStore } from "../stores/PropertiesStore";
import RecommendedProperties from "./RecommendedProperties.vue";
export default {
  components: {
    RecommendedProperties,
  },

  computed: {
    isLoading() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.isLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
</style>
