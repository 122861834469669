<template>
    <div class="about-us">
        <p>
            Kupiimot.EU може да бъде Вашето доверено място за намиране на недвижими имоти в България.
        </p>
        <p>
            Целта на Kupiimot.EU е да ви предложим възможно най-широка гама от недвижими имоти - от апартаменти в градовете до вили на морския бряг. Независимо дали търсите жилище за постоянно живеене, инвестиционен обект или ваканционна вила, ние сме тук, за да Ви  покажем огромен брой оферти. за да се  насочите лесно в правилната посока.
        </p>
        <p>
            Брокери и експерти в недвижимите имоти са готови да ви предоставят необходимата информация и съвети за всеки обект който разглеждате. Просто се свтржете с тях, за да направите своя информиран избор.
            Нямаме търпение да Ви помогнем в реализирането на вашите недвижими мечти.
            <span>Имате въпроси? Пишете ни на: <b>admin@kupiimot.eu</b></span>
        </p>
    </div>
</template>

<style lang="scss" scoped>
.about-us {
    width: 90%;
    margin: auto;
    height: 100vh;
    p {
        width: 80%;
        margin: 25px auto;
        text-align: center;
        span {
            display: block;
            margin-top: 50px;
            font-weight: 700;
            b {
                color: #a90831;
            }
        }
    }
}
</style>