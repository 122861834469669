<template>
  <ul class="desktop-navigation">
    <router-link to="/"><li> Начало</li></router-link>
    <router-link to="/for-sale"><li>Продажба</li></router-link>
    <router-link to="/for-rent"><li>Наем</li></router-link>
    <router-link to="/about-us"><li>За нас</li></router-link>
  </ul>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
a.router-link-active {
  color: $color-theme-text-hover;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
