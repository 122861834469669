<template>
  <div class="top-infobar-wrapper">
    <p><span>{{ totalItems }}</span> имота в категорията.</p>
  </div>
</template>

<script>
  import { usePropertiesStore } from "../../stores/PropertiesStore";
export default {
  props: ["data", "itemsPerPage", "currentPage"],

  computed: {
    totalItems() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.totalItems;
},
  }
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
span {
  color: $color-theme-text-hover;
}
</style>
