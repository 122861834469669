<template>
  <div>
    <div>
      <mobile-header></mobile-header>
      <desktop-header></desktop-header>
    </div>
    <div>
      <router-view></router-view>
    </div>
    <div>
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import TheFooter from './components/footer/TheFooter.vue';
export default {
  components: { TheFooter },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap");
@import "../assets/scss/main.scss";

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 600; 
}

</style>
